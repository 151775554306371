
import GLazyImage from '../g-lazy-image';
import GSpacer from '../g-spacer';
import richtext from '~/utils/richtext';
import parseLinkHref from '~/utils/parseLinkHref';

export default {
  name: 'g-cards',
  components: {
    GLazyImage,
    GSpacer,
  },
  props: {
    cards: {
      type: Array,
      required: true,
    },
    imgWidth: {
      type: Number,
      default: 130,
    },
    imgHeight: {
      type: Number,
      default: 130,
    },
    titleFont: {
      type: Number,
      default: 18,
    },
    paragraphFont: {
      type: Number,
      default: 16,
    },
    bodySpacing: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      textColor: '#000',
    };
  },
  computed: {
    cssVars() {
      return {
        '--titleFont': this.titleFont + 'px',
        '--paragraphFont': this.paragraphFont + 'px',
        '--bodySpacing': this.bodySpacing + 'px',
        '--cardWidth': this.cards.length <= 4 ? '50%' : '33%',
        '--noWrap': this.cards.length <= 4 ? 'nowrap' : 'wrap',
      };
    },
  },
  methods: {
    paragraph(p) {
      if (typeof p !== 'undefined') {
        return richtext(JSON.parse(JSON.stringify(p)));
      }
      return '';
    },
    parseLinkHref,
    hasImage(icon) {
      return icon.imagePublicUrl !== '';
    },
  },
};
